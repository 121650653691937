import * as React from "react"
import Layout from "../components/layout"
import '../components/index.css'
import '../components/signup.css'


const IndexPage = ({ location }) => {
    const [token, setToken] = React.useState(null)
    const [verificationSuccessful, setVerificationSuccessful] = React.useState(null)

    React.useEffect(() => {
        if (window !== undefined) {
            const params = new URLSearchParams(window.location.search)
            setToken(params.get("token"))
        }
    }, [])

    React.useEffect(() => {
        if (token) {
            fetch("https://api.thefoodapi.com/verify", {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify({ token })
            })
                .then(resp => {
                    if (resp.status === 200) {
                        window.location = '/account/keys?welcome=true'
                    } else {
                        setVerificationSuccessful(false)
                    }
                })
        }
    }, [token])

    return <>
        {(verificationSuccessful !== null && !verificationSuccessful) && <Layout location={location}>
            <h2 style={{ marginTop: "8rem", textAlign: "center" }}>We could not verify your account.</h2>
            <div style={{ marginTop: "4rem", textAlign: "center" }}>Please contact our <a href="mailto:team@thefoodapi.com" target="_blank">team</a></div>
        </Layout>}
    </>
}

export default IndexPage

export const Head = () => <title>Register Page</title>
